@tailwind base;

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom utilities */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

@layer utilities {
  .icon-stories-controls {
    @apply w-6 h-6 text-white;
  }
}
